import React from 'react'
import './style.css'
import taxionspotLogo from '../../images/taxionspotemailicon.png'
import locationFrom from '../../images/locationFromIcon.png'
import locationTo from '../../images/locationToIcon.png'
import DummyUser from '../../images/dummyUser.png'
import facebookIcon from '../../images/facebookIcon.png'
import twitterIcon from '../../images/twitterIcon.png'
import instagramIcon from '../../images/instagramIcon.png'
const EmailTemplate = () => {
  return (
    <div className='pageStyle'>
      <img className='imageStyle' src={taxionspotLogo} />
      <div className='rideLocationDetail'>
        <div className='d-flex flex-column py-4'>
          <div className='col-12 d-flex justify-content-between my-3'>
            <div className='col-8 row'>
              <div className='col-2 d-flex justify-content-start align-items-center'>
                <img src={locationFrom} />
                <p className='fromText mb-0 ml-2'>from</p>
              </div>
              <div className='col-10 d-flex flex-column justify-content-center pl-4 '>
                <p className='locationHeadingText mb-0'>Jan de Rijstraat 11</p>
                <p className='locationDescriptionText mb-0'>
                  3623ED Best, Netherland
								</p>
              </div>
            </div>
            <div className='col-4 d-flex flex-column justify-content-center align-items-end'>
              <p className='distanceHeadingText mb-0'>Distance</p>
              <p className='distanceText mb-0'>14.79 KM</p>
            </div>
          </div>
          <div className='col-12 d-flex justify-content-between my-3'>
            <div className='col-8 row'>
              <div className='col-2 d-flex justify-content-start align-items-center'>
                <img src={locationTo} />
                <p className='fromText mb-0 ml-2'>to</p>
              </div>
              <div className='col-10 d-flex flex-column justify-content-center pl-4'>
                <p className='locationHeadingText mb-0'>Jan de Rijstraat 11</p>
                <p className='locationDescriptionText mb-0'>
                  3623ED Best, Netherland
								</p>
              </div>
            </div>
            <div className='col-4 d-flex flex-column justify-content-center align-items-end'>
              <p className='distanceHeadingText mb-0'>Distance</p>
              <p className='distanceText mb-0'>14.79 KM</p>
            </div>
          </div>
        </div>
      </div>
      <div className='py-4'>
        <p className='col-12 summaryHeading'>Summary</p>
        <div className='col-12 d-flex justify-content-between'>
          <p className='summaryText'>Meter Fare</p>
          <p className='summaryText'>$3.00</p>
        </div>
        <div className='col-12 d-flex justify-content-between'>
          <p className='summaryText'>Checked Bag</p>
          <p className='summaryText'>$30.00</p>
        </div>
        <div className='col-12 d-flex justify-content-between'>
          <p className='summaryText'>Taxes and carries-imposed fees</p>
          <p className='summaryText'>$30.00</p>
        </div>
      </div>
      <div className='pt-4'>
        <div className='col-12 d-flex justify-content-between'>
          <p className='totalText'>Total</p>
          <p className='totalAmountText'>$360.00</p>
        </div>
        <p className='col-12'>Payment Date 05/01/2019</p>
        <p className='col-12'>Payment Amount $360.00</p>
        <p className='col-12'>*** Approved *** XXXXXXXXXXXX1234</p>
        <p className='col-12'>Exp. Date 08/25</p>
      </div>
      <div className='pt-4'>
        <div className='col-12'>
          <div className='col-8 row'>
            <div className='col-2'>
              <img src={DummyUser} />
            </div>
            <div className='col-6 mt-2'>
              <div className='col-12 youDriverText'>YOUR DRIVER</div>
              <div className='col-12 driverNameText'>Hello World</div>
              <div className='col-12 driverTextNumberText'>IV301</div>
            </div>
          </div>
          <div className='col-4'></div>
        </div>
      </div>
      <div className='thankYouContainer mt-3'>
        <p className='thankYouText pt-2'>Thank you for Ride with us!</p>
      </div>
      <div className='footerContainer'>
        <div className='col-12 p-4'>
          <p className='footerBigText'>We’d love to hear from you!</p>
          <p className='footerSmallText'>
            Help us improve by sharing your feedback in this short survey.
					</p>
        </div>
        <div className='col-12 d-flex justify-content-center'>
          <img className='mx-2' src={facebookIcon} alt='facebook_icon' />
          <img className='mx-2' src={twitterIcon} alt='twitter_icon' />
          <img className='mx-2' src={instagramIcon} alt='instagram_icon' />
        </div>
        <p className='footerSmallText mt-3'>
          Copyright © 2020 TaxiOnSpot All Rights Reserved.
				</p>
      </div>
    </div>
  )
}

export { EmailTemplate }
